import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import DealerRegistrationPage from '~/components/DealerRegistration'

const RegisterDealer = ({ data }) => {
  const { page } = data

  useEffect(() => {
    if (!page) {
      navigate('/')
    }
  }, [])

  return (
    <Layout>
      {page && (
        <>
          <Metadata
            title={page.metaTitle || page.title}
            description={
              page.metaDescription?.metaDescription ||
              `Super73's ${page.title} page default description`
            }
          />
          <DealerRegistrationPage page={page} />
        </>
      )}
    </Layout>
  )
}

export default RegisterDealer

export const query = graphql`
  query RegisterDealerPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "dealer-inquiry" }
      node_locale: { eq: $locale }
    ) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      hero {
        ...ContentHeroFragment
      }
    }
  }
`
