/** @jsx jsx */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Container, Flex, Heading, Text } from '@theme-ui/components'
import { jsx } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

const DealerRegistrationPage = ({ page }) => {
  return (
    <Container variant="fullWidth">
      <Helmet>
        <script src={withPrefix('form-script.js')} type="text/javascript" />
      </Helmet>
      <Flex
        sx={{
          width: '100%',
          backgroundColor: page.hero.image ? '#212121' : 'transparent',
          color: page.hero.image ? 'white' : 'black',
          textAlign: 'center',
          flexDirection: ['column', 'row', null]
        }}
      >
        {page.hero.image && (
          <GatsbyImage
            image={page.hero.image.gatsbyImageData}
            alt={page.hero.image?.description ?? page.hero.image?.title}
            sx={{ width: ['100%', '50%', '65%'], maxHeight: '843px' }}
            imgStyle={{ objectPosition: 'top' }}
          />
        )}
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto 2.4rem',
            py: [page.hero.image ? '5rem' : '2rem', 0, null]
          }}
        >
          <Heading
            sx={{
              fontSize: [null, '3.2rem', '4.2rem'],
              fontWeight: 'body',
              mb: '1rem',
              px: '1rem'
            }}
          >
            {page.hero.heading?.heading}
          </Heading>
          {page.hero.subheading && (
            <Text sx={{ fontSize: '1.6rem', fontWeight: 'light' }}>
              {page.hero.subheading.subheading}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex
        sx={{
          maxWidth: '760px',
          px: '15px',
          flexDirection: 'column',
          margin: '0 auto'
        }}
      >
        <Text sx={{ m: 0, fontWeight: 'light', fontSize: '1.4rem' }}>
          Thank you for your enthusiasm for Super73 and your interest in
          becoming a dealer or distributor. Please note that we prefer at least
          1 year of experience with an existing business structure. Please fill
          out your business details below and we will get back to you as soon as
          possible. Thank you!
        </Text>
      </Flex>
      <Flex
        sx={{
          my: '16px',
          height: 'max-content',
          width: '100%',
          justifyContent: 'center'
        }}
      >
        <iframe
          src="https://forms.monday.com/forms/embed/fe8b07856d05e06081f9dd108afe257e?r=use1"
          aria-label="Dealer Inquiry Form"
          width="650"
          sx={{
            height: '1100px',
            border: 0,
            boxShadow: ' 5px 5px 56px 0px rgba(0,0,0,0.25)',
            overflow: 'hidden'
          }}
        />
      </Flex>
    </Container>
  )
}
export default DealerRegistrationPage
